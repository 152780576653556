import React, {createContext, useState} from 'react';
import UserService from "../services/UserService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [userList, setUserList] = useState([]);

    const [managerLoading, setManagerLoading] = useState(false);
    const [managerList, setManagerList] = useState([]);

    const getUserList = async params => {
        try {

            setLoading(true);

            const res = await UserService.getUserList(params);
            setUserList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getManagerList = async params => {
        try {

            setManagerLoading(true);

            const res = await UserService.getUserList(params);
            setManagerList(res.data.content);

            setManagerLoading(false);

        } catch (error) {

            setManagerLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <UserContext.Provider
            value={{
                loading,
                totalElements,
                userList,
                getUserList,
                managerLoading,
                managerList,
                getManagerList,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
