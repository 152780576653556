import React from 'react';

const ImageView = ({url, style, onClick, cursor = "pointer", classname=""}) => {

    if (url) {
        return <img
            style={{...style, cursor}}
            src={url}
            onClick={onClick ? onClick : null}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/images/brand_image.png"
            }}
            className={classname}
            alt="no_image"
        />
    }

    return <img style={style} src="/assets/images/brand_image.png" alt="no_image"/>

}

export default ImageView;
