import privateAPI from "../rest_handlers/privateAPI";
import * as PATH from "../configs/APIUrl";

export default class SettingService {

    static getSetting = () =>
        privateAPI.get(PATH.GET_SETTINGS_URL);

    static getSettingApiUrl = params =>
        privateAPI.get(PATH.GET_SETTINGS_API_URL, {params});

    static updateSetting = data =>
        privateAPI.post(PATH.UPDATE_SETTINGS_URL, data);

}
