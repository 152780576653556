import React from 'react';
import ImageView from "../common/ImageView";

import "./page_content_footer.scss";

const PageContentFooter = () => {
    return (
        <div className="page-content-footer">
            <span className="text-primary-gradient-color">
                This product is powered by
            </span>
            <ImageView
                url="/assets/defai_logo.svg"
                style={{height: "100%"}}
            />
        </div>
    );
};

export default PageContentFooter;