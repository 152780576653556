import * as PATH from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class TelegramService {

    static registerTelegram = data =>
        privateAPI.post(PATH.REGISTER_TELEGRAM_URL, data, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"}
        });

    static getTelegramUserList = async params =>
        privateAPI.get(PATH.GET_ALL_TELEGRAM_USER_URL, {params});

    static telegramBotToggle = async data =>
        privateAPI.put(PATH.TELEGRAM_USER_TOGGLE_URL, data);

    static deleteTelegramBot = async params =>
        privateAPI.delete(PATH.DELETE_TELEGRAM_USER_URL, {params});

}
