import {CONDITION_NODE_TYPE, DEV_WEBHOOK_NODE_TYPE, QUESTION_NODE_TYPE, REPLY_TEXT_NODE_TYPE} from "./Constant";

export const workflowTemplates = {
    data : {
        content: [
            // {
            //     "metadata": {
            //         "topicName": "AppointmentBook",
            //         "description": "Appointment Book",
            //         "chatbot": "Dev bot 9",
            //         "language": "ENG",
            //         "stage": "UAT",
            //         "chatbotId": 22,
            //         "languageId": 1,
            //         "id": 355
            //     },
            //     "nodes": [
            //         {
            //             "id": "1388",
            //             "position": {
            //                 "x": 307,
            //                 "y": 175
            //             },
            //             "data": {
            //                 "label": "New Block",
            //                 "children": [
            //                     {
            //                         "id": 1008,
            //                         "label": "Question",
            //                         "nodeType": "QUESTION",
            //                         "stateConfig": {
            //                             "name": "name",
            //                             "variable": "name",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 669,
            //                                     "displayText": "May I have your name, please?"
            //                                 }
            //                             ]
            //                         }
            //                     },
            //                     {
            //                         "id": 559,
            //                         "label": "Question",
            //                         "nodeType": "QUESTION",
            //                         "stateConfig": {
            //                             "name": "email",
            //                             "variable": "email",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 318,
            //                                     "displayText": "Thanks,{name}. Can you share your email ?"
            //                                 }
            //                             ]
            //                         }
            //                     }
            //                 ]
            //             },
            //             "type": "MainWorkflowNode",
            //             "dragHandle": ".custom-drag-handle",
            //             "style": {
            //                 "borderRadius": "12px",
            //                 "width": "300px"
            //             },
            //             "width": 300,
            //             "height": 190,
            //             "selected": false,
            //             "dragging": false,
            //             "positionAbsolute": {
            //                 "x": 307,
            //                 "y": 175
            //             }
            //         },
            //         {
            //             "id": "1022",
            //             "position": {
            //                 "x": 663,
            //                 "y": 205
            //             },
            //             "data": {
            //                 "label": "New Block",
            //                 "children": [
            //                     {
            //                         "id": 1633,
            //                         "label": "Question",
            //                         "nodeType": "QUESTION",
            //                         "stateConfig": {
            //                             "name": "appointmentType",
            //                             "variable": "appointmentType",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 868,
            //                                     "displayText": "Could you please specify the service you are looking for: Echocardiogram, ECG, Exercise Stress Test, Ambulatory Blood Pressure Monitoring, Holter Monitoring, or Consultations?"
            //                                 }
            //                             ]
            //                         }
            //                     },
            //                     {
            //                         "id": 1613,
            //                         "label": "Question",
            //                         "nodeType": "QUESTION",
            //                         "stateConfig": {
            //                             "name": "ask-dateTime",
            //                             "variable": "dateTime",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 599,
            //                                     "displayText": "When are you planning to schedule the appointment ?"
            //                                 }
            //                             ]
            //                         }
            //                     },
            //                     {
            //                         "id": 588,
            //                         "label": "Question",
            //                         "nodeType": "QUESTION",
            //                         "stateConfig": {
            //                             "name": "confirm",
            //                             "variable": "confirm",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 548,
            //                                     "displayText": "Thank you for providing the information. Summary of your appointment: Email: {email}, Name: {name}, Service: {appointmentType}, Date and Time: {dateTime}. Please confirm the details. Answer yes/no."
            //                                 }
            //                             ]
            //                         }
            //                     },
            //                     {
            //                         "id": 919,
            //                         "label": "Condition",
            //                         "nodeType": "CONDITION",
            //                         "stateConfig": {
            //                             "choices": [
            //                                 {
            //                                     "id": 514,
            //                                     "operator": "OR",
            //                                     "expressionList": [
            //                                         {
            //                                             "id": 414,
            //                                             "operator": "is",
            //                                             "variable": "confirm",
            //                                             "value": "yes"
            //                                         }
            //                                     ],
            //                                     "next": "1028"
            //                                 },
            //                                 {
            //                                     "id": 514.3604773477849,
            //                                     "operator": "DEFAULT",
            //                                     "name": "",
            //                                     "active": true
            //                                 }
            //                             ],
            //                             "next": "1096"
            //                         }
            //                     }
            //                 ]
            //             },
            //             "type": "MainWorkflowNode",
            //             "dragHandle": ".custom-drag-handle",
            //             "style": {
            //                 "borderRadius": "12px",
            //                 "width": "300px"
            //             },
            //             "width": 300,
            //             "height": 338
            //         },
            //         {
            //             "id": "1787",
            //             "position": {
            //                 "x": 1133.9475331076146,
            //                 "y": 117.87834371081073
            //             },
            //             "data": {
            //                 "label": "New Block",
            //                 "children": [
            //                     {
            //                         "id": 1028,
            //                         "label": "Dev Webhook",
            //                         "nodeType": "DEV_WEBHOOK",
            //                         "stateConfig": {
            //                             "name": "send-email",
            //                             "webhookConfig": {
            //                                 "url": "https://5e7roplfzvvmd6ebnt2mkawtxa0lpfas.lambda-url.us-east-1.on.aws",
            //                                 "method": "POST",
            //                                 "requestBody": "{\n  \"clinicEmail\": \"CLINICEMAIL\",\n  \"email\": {email},\n  \"name\": {name},\n  \"appointmentType\": {appointmentType},\n  \"dateTime\": {dateTime},\n}"
            //                             }
            //                         }
            //                     },
            //                     {
            //                         "id": 1023,
            //                         "label": "Reply Text",
            //                         "nodeType": "REPLY_TEXT",
            //                         "stateConfig": {
            //                             "name": "success",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 558,
            //                                     "displayText": "success"
            //                                 }
            //                             ]
            //                         }
            //                     }
            //                 ]
            //             },
            //             "type": "MainWorkflowNode",
            //             "dragHandle": ".custom-drag-handle",
            //             "style": {
            //                 "borderRadius": "12px",
            //                 "width": "300px"
            //             },
            //             "width": 300,
            //             "height": 188,
            //             "selected": false,
            //             "positionAbsolute": {
            //                 "x": 1133.9475331076146,
            //                 "y": 117.87834371081073
            //             },
            //             "dragging": false
            //         },
            //         {
            //             "id": "731",
            //             "position": {
            //                 "x": 232,
            //                 "y": 668
            //             },
            //             "data": {
            //                 "label": "New Block",
            //                 "children": [
            //                     {
            //                         "id": 1096,
            //                         "label": "Reply Text",
            //                         "nodeType": "REPLY_TEXT",
            //                         "stateConfig": {
            //                             "name": "else",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 577,
            //                                     "displayText": "sure what are the changes you need to make"
            //                                 }
            //                             ]
            //                         }
            //                     }
            //                 ]
            //             },
            //             "type": "MainWorkflowNode",
            //             "dragHandle": ".custom-drag-handle",
            //             "style": {
            //                 "borderRadius": "12px",
            //                 "width": "300px"
            //             },
            //             "width": 300,
            //             "height": 138,
            //             "selected": false,
            //             "positionAbsolute": {
            //                 "x": 232,
            //                 "y": 668
            //             },
            //             "dragging": false
            //         },
            //         {
            //             "id": "983",
            //             "position": {
            //                 "x": -77,
            //                 "y": -44
            //             },
            //             "data": {
            //                 "label": "New Block",
            //                 "children": [
            //                     {
            //                         "id": 1297,
            //                         "label": "Reply Text",
            //                         "nodeType": "REPLY_TEXT",
            //                         "stateConfig": {
            //                             "name": "greet",
            //                             "displayTextOptions": [
            //                                 {
            //                                     "id": 953,
            //                                     "displayText": "hello"
            //                                 }
            //                             ]
            //                         }
            //                     }
            //                 ]
            //             },
            //             "type": "MainWorkflowNode",
            //             "dragHandle": ".custom-drag-handle",
            //             "style": {
            //                 "borderRadius": "12px",
            //                 "width": "300px"
            //             },
            //             "width": 300,
            //             "height": 138,
            //             "selected": false,
            //             "positionAbsolute": {
            //                 "x": -77,
            //                 "y": -44
            //             },
            //             "dragging": false
            //         }
            //     ],
            //     "edges": [
            //         {
            //             "source": "1388",
            //             "sourceHandle": "source-1388-559",
            //             "target": "1022",
            //             "targetHandle": "target-1022",
            //             "type": "custom",
            //             "markerEnd": {
            //                 "type": "arrow",
            //                 "width": 20,
            //                 "height": 20,
            //                 "color": "#969b9f"
            //             },
            //             "style": {
            //                 "strokeWidth": 2,
            //                 "stroke": "#969b9f"
            //             },
            //             "id": "reactflow__edge-1388source-1388-559-1022target-1022"
            //         },
            //         {
            //             "source": "1022",
            //             "sourceHandle": "source-condition-919-514",
            //             "target": "1787",
            //             "targetHandle": "target-1787",
            //             "type": "custom",
            //             "markerEnd": {
            //                 "type": "arrow",
            //                 "width": 20,
            //                 "height": 20,
            //                 "color": "#969b9f"
            //             },
            //             "style": {
            //                 "strokeWidth": 2,
            //                 "stroke": "#969b9f"
            //             },
            //             "id": "reactflow__edge-1022source-condition-919-514-1787target-1787"
            //         },
            //         {
            //             "source": "1022",
            //             "sourceHandle": "source-else-condition-919",
            //             "target": "731",
            //             "targetHandle": "target-731",
            //             "type": "custom",
            //             "markerEnd": {
            //                 "type": "arrow",
            //                 "width": 20,
            //                 "height": 20,
            //                 "color": "#969b9f"
            //             },
            //             "style": {
            //                 "strokeWidth": 2,
            //                 "stroke": "#969b9f"
            //             },
            //             "id": "reactflow__edge-1022source-else-condition-919-731target-731"
            //         },
            //         {
            //             "source": "983",
            //             "sourceHandle": "source-983-1297",
            //             "target": "1388",
            //             "targetHandle": "target-1388",
            //             "type": "custom",
            //             "markerEnd": {
            //                 "type": "arrow",
            //                 "width": 20,
            //                 "height": 20,
            //                 "color": "#969b9f"
            //             },
            //             "style": {
            //                 "strokeWidth": 2,
            //                 "stroke": "#969b9f"
            //             },
            //             "id": "reactflow__edge-983source-983-1297-1388target-1388"
            //         },
            //         {
            //             "source": "731",
            //             "sourceHandle": "source-731-1096",
            //             "target": "1388",
            //             "targetHandle": "target-1388",
            //             "type": "custom",
            //             "markerEnd": {
            //                 "type": "arrow",
            //                 "width": 20,
            //                 "height": 20,
            //                 "color": "#969b9f"
            //             },
            //             "style": {
            //                 "strokeWidth": 2,
            //                 "stroke": "#969b9f"
            //             },
            //             "id": "reactflow__edge-731source-731-1096-1388target-1388"
            //         }
            //     ]
            // },
            {
                "metadata": {
                    "topicName": "PizzaOrder",
                    "chatbot": "Dev bot 9",
                    "language": "ENG",
                    "description": "order pizza",
                    "chatbotId": null,
                    "languageId": 1,
                    "stage": "UAT",
                    "id": 68
                },
                "nodes": [
                    {
                        "id": "374",
                        "position": {
                            "x": 953,
                            "y": 325
                        },
                        "data": {
                            "label": "Pizza Details",
                            "children": [
                                {
                                    "id": 382,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "displayTextOptions": [
                                            {
                                                "id": 746,
                                                "displayText": "Great! We have a variety of pizzas to choose from, including classics like Pepperoni, Margherita, Hawaiian, Meat Lovers, and Veggie Supreme. What type of pizza would you like?"
                                            }
                                        ],
                                        "variable": "pizza_type",
                                        "name": "ask-pizza-type"
                                    }
                                },
                                {
                                    "id": 842,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "variable": "pizza_size",
                                        "displayTextOptions": [
                                            {
                                                "id": 526,
                                                "displayText": "Got it! What size would you like? We offer small, medium, large, and extra-large options."
                                            }
                                        ],
                                        "name": "ask-pizze-size"
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#D3E5F4"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 184,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 953,
                            "y": 325
                        },
                        "dragging": false
                    },
                    {
                        "id": "1072",
                        "position": {
                            "x": 570,
                            "y": 150
                        },
                        "data": {
                            "label": "New Block",
                            "children": [
                                {
                                    "id": 910,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "greet",
                                        "displayTextOptions": [
                                            {
                                                "id": 553,
                                                "displayText": "Hello! Welcome to PizzaPizza! I’m here to help you order a delicious pizza. Would you start with ordering a pizza?"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 570,
                            "y": 150
                        },
                        "dragging": false
                    },
                    {
                        "id": "1339",
                        "position": {
                            "x": 1727.8968290469263,
                            "y": 268.7848914012599
                        },
                        "data": {
                            "label": "Confirm Order with Extra",
                            "children": [
                                {
                                    "id": 1409,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "displayTextOptions": [
                                            {
                                                "id": 442,
                                                "displayText": "Here's your order summary: A {pizza_size}\n{pizza_type} pizza. Extra: {extra_toppings}. Does that look good to you?"
                                            }
                                        ],
                                        "name": "confirm-before"
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1727.8968290469263,
                            "y": 268.7848914012599
                        },
                        "dragging": false
                    },
                    {
                        "id": "1154",
                        "position": {
                            "x": 2029.3920851810662,
                            "y": 619.5543052412031
                        },
                        "data": {
                            "label": "Billing Confirm",
                            "children": [
                                {
                                    "id": 581,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "total-amount",
                                        "displayTextOptions": [
                                            {
                                                "id": 536,
                                                "displayText": "The total amount for your order comes to $28.00. "
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1082,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "variable": "confirm",
                                        "name": "ask-confirm-amount",
                                        "displayTextOptions": [
                                            {
                                                "id": 473,
                                                "displayText": "Does everything look correct to you?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1235,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 137,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 389,
                                                        "operator": "is",
                                                        "variable": "confirm",
                                                        "value": "yes"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": 137.09056556892295,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 280,
                        "selected": false,
                        "dragging": false,
                        "positionAbsolute": {
                            "x": 2029.3920851810662,
                            "y": 619.5543052412031
                        }
                    },
                    {
                        "id": "690",
                        "position": {
                            "x": 583,
                            "y": 712.4000000000001
                        },
                        "data": {
                            "label": "Update Message",
                            "children": [
                                {
                                    "id": 1003,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "update-message",
                                        "displayTextOptions": [
                                            {
                                                "id": 966,
                                                "displayText": "What adjustments do you need to make"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 583,
                            "y": 712.4000000000001
                        },
                        "dragging": false
                    },
                    {
                        "id": "936",
                        "position": {
                            "x": 1346,
                            "y": 402
                        },
                        "data": {
                            "label": "Customization ",
                            "children": [
                                {
                                    "id": 594,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "displayTextOptions": [
                                            {
                                                "id": 958,
                                                "displayText": "Great choice! A {pizza_size} {pizza_type} pizza. Would you like any customizations, like extra cheese or adding more toppings?"
                                            }
                                        ],
                                        "variable": "extra_toppings",
                                        "name": "ask-extra-toppings"
                                    }
                                },
                                {
                                    "id": 1600,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 292,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 343,
                                                        "operator": "is not",
                                                        "variable": "extra_toppings",
                                                        "value": "no"
                                                    }
                                                ]
                                            },
                                            {
                                                "id": 292.926981465864,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#D3E5F4"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 228,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1346,
                            "y": 402
                        },
                        "dragging": false
                    },
                    {
                        "id": "971",
                        "position": {
                            "x": 2451,
                            "y": 664
                        },
                        "data": {
                            "label": "Delivery Details",
                            "children": [
                                {
                                    "id": 1655,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "variable": "delivery_address",
                                        "name": "ask-delivery-address",
                                        "displayTextOptions": [
                                            {
                                                "id": 550,
                                                "displayText": "Please provide your delivery address"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1209,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "ask-additional-note",
                                        "variable": "additional_note",
                                        "displayTextOptions": [
                                            {
                                                "id": 50,
                                                "displayText": "Do you have any additional note"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 184,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 2451,
                            "y": 664
                        },
                        "dragging": false
                    },
                    {
                        "id": "860",
                        "position": {
                            "x": 2838,
                            "y": 641.8
                        },
                        "data": {
                            "label": "Place Order",
                            "children": [
                                {
                                    "id": 1006,
                                    "label": "Dev Webhook",
                                    "nodeType": "DEV_WEBHOOK",
                                    "stateConfig": {
                                        "name": "api-invoke"
                                    }
                                },
                                {
                                    "id": 54,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "end-message",
                                        "displayTextOptions": [
                                            {
                                                "id": 883,
                                                "displayText": "Thank you! We can have your order delivered in approximately 30-45 minutes."
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#D3E5F4"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 1
                        },
                        "width": 300,
                        "height": 182,
                        "selected": true,
                        "positionAbsolute": {
                            "x": 2838,
                            "y": 641.8
                        },
                        "dragging": false
                    }
                ],
                "edges": [
                    {
                        "id": "edge-699-374",
                        "source": "699",
                        "target": "374",
                        "sourceHandle": "source-699-910-right",
                        "targetHandle": "top-left",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1072-374",
                        "source": "1072",
                        "target": "374",
                        "sourceHandle": "source-1072-910-right",
                        "targetHandle": "top-left",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1339-1154",
                        "source": "1339",
                        "target": "1154",
                        "sourceHandle": "source-1339-1409-right",
                        "targetHandle": "top-left",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-936-1339",
                        "source": "936",
                        "target": "1339",
                        "sourceHandle": "source-condition-1600-292-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-374-936",
                        "source": "374",
                        "target": "936",
                        "sourceHandle": "source-374-842-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-690-374",
                        "source": "690",
                        "target": "374",
                        "sourceHandle": "source-690-1003-right",
                        "targetHandle": "left-top",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "source": "1154",
                        "sourceHandle": "source-else-condition-1235-left",
                        "target": "690",
                        "targetHandle": "right-top",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        },
                        "id": "reactflow__edge-1154source-else-condition-1235-right-690right-bottom",
                        "selected": false
                    },
                    {
                        "id": "edge-936-1154",
                        "source": "936",
                        "target": "1154",
                        "sourceHandle": "source-else-condition-1600-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1154-971",
                        "source": "1154",
                        "target": "971",
                        "sourceHandle": "source-condition-1235-137-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-971-860",
                        "source": "971",
                        "target": "860",
                        "sourceHandle": "source-971-1209-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    }
                ]
            },
            {
                "metadata": {
                    "topicName": "Abdomincal Rigidity",
                    "chatbot": "testtest",
                    "language": "ENG",
                    "description": "Abdominal Rigidity Diagnostic",
                    "chatbotId": null,
                    "languageId": 1,
                    "stage": "UAT",
                    "id": 290
                },
                "nodes": [
                    {
                        "id": "1034",
                        "position": {
                            "x": 656.1548702880407,
                            "y": 175.82843857089978
                        },
                        "data": {
                            "label": "Information gathering",
                            "children": [
                                {
                                    "id": 673,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Start Diagnostic",
                                        "displayTextOptions": [
                                            {
                                                "id": 836,
                                                "displayText": "We will start the Abdominal Rigidity Diagnostic.\nWe will first gather some important information."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1555,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Patient First Name",
                                        "variable": "PatientFirstName",
                                        "displayTextOptions": [
                                            {
                                                "id": 744,
                                                "displayText": "Please enter patient's first name:"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1476,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Patient's Last Name",
                                        "variable": "PatientLastName",
                                        "displayTextOptions": [
                                            {
                                                "id": 103,
                                                "displayText": "Please enter patient's last name:"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1518,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Patient's DOB",
                                        "variable": "PatientDOB",
                                        "displayTextOptions": [
                                            {
                                                "id": 568,
                                                "displayText": "Please enter patient's DOB"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 878,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Time",
                                        "variable": "When",
                                        "displayTextOptions": [
                                            {
                                                "id": 571,
                                                "displayText": "When did the patient starts experiencing abdominal rigidity?"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 340,
                        "selected": false,
                        "dragging": false,
                        "positionAbsolute": {
                            "x": 656.1548702880407,
                            "y": 175.82843857089978
                        }
                    },
                    {
                        "id": "643",
                        "position": {
                            "x": 710.0500652299139,
                            "y": 575.7801011702326
                        },
                        "data": {
                            "label": "Tenderness",
                            "children": [
                                {
                                    "id": 1531,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "FirstSymptomTendernessandrebound",
                                        "displayTextOptions": [
                                            {
                                                "id": 724,
                                                "displayText": "We will start the diagnostic questioning now."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 611,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Tenderness and Rebound present",
                                        "variable": "TenderRebound",
                                        "displayTextOptions": [
                                            {
                                                "id": 435,
                                                "displayText": "Does patient feel tenderness and rebound tenderness? \nAnswer yes or no."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 628,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 63,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 419,
                                                        "operator": "is",
                                                        "variable": "TenderRebound",
                                                        "value": "yes"
                                                    }
                                                ],
                                                "next": "823"
                                            },
                                            {
                                                "id": 63.57127860942534,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ],
                                        "next": "520"
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#D3E5F4"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 280,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 710.0500652299139,
                            "y": 575.7801011702326
                        },
                        "dragging": false
                    },
                    {
                        "id": "1169",
                        "position": {
                            "x": 1201.7704661271773,
                            "y": 219.50212729257845
                        },
                        "data": {
                            "label": "Does Trauma present",
                            "children": [
                                {
                                    "id": 823,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Trauma",
                                        "variable": "Trauma",
                                        "displayTextOptions": [
                                            {
                                                "id": 156,
                                                "displayText": "Has patient experience trauma?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 718,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 671,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 507,
                                                        "operator": "is",
                                                        "variable": "Trauma",
                                                        "value": "yes"
                                                    }
                                                ],
                                                "next": "968"
                                            },
                                            {
                                                "id": 671.4805228420138,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ],
                                        "next": "693"
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 228,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1201.7704661271773,
                            "y": 219.50212729257845
                        },
                        "dragging": false
                    },
                    {
                        "id": "1393",
                        "position": {
                            "x": 1274.3609741370717,
                            "y": 698.5546744530551
                        },
                        "data": {
                            "label": "Voluntary Regidity",
                            "children": [
                                {
                                    "id": 520,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Voluntary Regidity",
                                        "displayTextOptions": [
                                            {
                                                "id": 699,
                                                "displayText": "Since patient does not feel tenderness or rebound tenderness.  It should be a case of voluntary rigidity.  Nothing to follow up."
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#E3E0F1"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1274.3609741370717,
                            "y": 698.5546744530551
                        },
                        "dragging": false
                    },
                    {
                        "id": "1364",
                        "position": {
                            "x": 1680.8457660229492,
                            "y": 109.2394884979642
                        },
                        "data": {
                            "label": "Refer to Specialist",
                            "children": [
                                {
                                    "id": 968,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Internal Rupture",
                                        "displayTextOptions": [
                                            {
                                                "id": 910,
                                                "displayText": "The cause of the abdominal rigidity due to:\n1. Rupture Spleen\n2. Other abdominal organ rupture or damage\n\nSuggest CT, ultrasound"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1680.8457660229492,
                            "y": 109.2394884979642
                        },
                        "dragging": false
                    },
                    {
                        "id": "506",
                        "position": {
                            "x": 1672.1756686017557,
                            "y": 411.56881815056477
                        },
                        "data": {
                            "label": "History of abdominal pain",
                            "children": [
                                {
                                    "id": 693,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Recuring abdominal pain",
                                        "variable": "Recuring",
                                        "displayTextOptions": [
                                            {
                                                "id": 320,
                                                "displayText": "Does patient has recurring abdominal pain?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1450,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 629,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 44,
                                                        "operator": "is",
                                                        "variable": "Recuring",
                                                        "value": "yes"
                                                    }
                                                ],
                                                "next": "1412"
                                            },
                                            {
                                                "id": 629.9070565033014,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ],
                                        "next": "1174"
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 228,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1672.1756686017557,
                            "y": 411.56881815056477
                        },
                        "dragging": false
                    },
                    {
                        "id": "1904",
                        "position": {
                            "x": 2065.4167153138865,
                            "y": 224.40808779436009
                        },
                        "data": {
                            "label": "Recurring",
                            "children": [
                                {
                                    "id": 1412,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Diagnose result 1",
                                        "displayTextOptions": [
                                            {
                                                "id": 968,
                                                "displayText": "The cause of the abdominal rigidity may be due to:\n1. Ruptured Peptic Ulcer\n2. Ruptured Diverticulum\n3. Perforated Gall Bladder"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#DDE4EA"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 2065.4167153138865,
                            "y": 224.40808779436009
                        },
                        "dragging": false
                    },
                    {
                        "id": "635",
                        "position": {
                            "x": 2096.5954503699136,
                            "y": 515.297785151988
                        },
                        "data": {
                            "label": "Not Recurring",
                            "children": [
                                {
                                    "id": 1174,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Not Recurring",
                                        "displayTextOptions": [
                                            {
                                                "id": 96,
                                                "displayText": "The cause of the patient's abdominal rigidity may be due to:\n1. Lobar Pneumonia\n2. Ruptured Appendix\n3. P.I.D.\n4. Black Widow Spider Bite\n5. Ruptured Ectopic Pregnancy\n6. Infectious Peritonitis\n7. Pancreattis\n8. Mesenteric\n9. Thrombosis\n10. Dissecting Aneurysm"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#DDE4EA"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 1
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 2096.5954503699136,
                            "y": 515.297785151988
                        },
                        "dragging": false
                    }
                ],
                "edges": [
                    {
                        "id": "edge-1034-643",
                        "source": "1034",
                        "target": "643",
                        "sourceHandle": "source-1034-878-left",
                        "targetHandle": "top-right",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1169-1364",
                        "source": "1169",
                        "target": "1364",
                        "sourceHandle": "source-condition-718-671-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-643-1393",
                        "source": "643",
                        "target": "1393",
                        "sourceHandle": "source-else-condition-628-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-643-1169",
                        "source": "643",
                        "target": "1169",
                        "sourceHandle": "source-condition-628-63-right",
                        "targetHandle": "left-top",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1169-506",
                        "source": "1169",
                        "target": "506",
                        "sourceHandle": "source-else-condition-718-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-506-1904",
                        "source": "506",
                        "target": "1904",
                        "sourceHandle": "source-condition-1450-629-right",
                        "targetHandle": "left-top",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-506-635",
                        "source": "506",
                        "target": "635",
                        "sourceHandle": "source-else-condition-1450-right",
                        "targetHandle": "left-top",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    }
                ]
            },
            {
                "metadata": {
                    "topicName": "Sleep Apnea Diagnostic",
                    "chatbot": "testtest",
                    "language": "ENG",
                    "description": "sleep apnea diagnostic",
                    "chatbotId": null,
                    "languageId": 1,
                    "stage": "UAT",
                    "id": 598
                },
                "nodes": [
                    {
                        "id": "1034",
                        "position": {
                            "x": 656.1548702880407,
                            "y": 175.82843857089978
                        },
                        "data": {
                            "label": "Information gathering",
                            "children": [
                                {
                                    "id": 673,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Start Diagnostic",
                                        "displayTextOptions": [
                                            {
                                                "id": 836,
                                                "displayText": "We will start the Sleep Apnea Diagnostic.\nWe will first gather some important information."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1555,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Patient First Name",
                                        "variable": "PatientFirstName",
                                        "displayTextOptions": [
                                            {
                                                "id": 744,
                                                "displayText": "Please enter patient's first name:"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1476,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Patient's Last Name",
                                        "variable": "PatientLastName",
                                        "displayTextOptions": [
                                            {
                                                "id": 103,
                                                "displayText": "Please enter patient's last name:"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 1518,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Patient's DOB",
                                        "variable": "PatientDOB",
                                        "displayTextOptions": [
                                            {
                                                "id": 568,
                                                "displayText": "Please enter patient's DOB"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 878,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "HowLong",
                                        "variable": "HowLong",
                                        "displayTextOptions": [
                                            {
                                                "id": 571,
                                                "displayText": "How long has the patient been bothered by sleep apnea?"
                                            }
                                        ]
                                    }
                                }
                            ]
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 340,
                        "selected": false,
                        "dragging": false,
                        "positionAbsolute": {
                            "x": 656.1548702880407,
                            "y": 175.82843857089978
                        }
                    },
                    {
                        "id": "643",
                        "position": {
                            "x": 667.240651002591,
                            "y": 592.9038668611616
                        },
                        "data": {
                            "label": "Snoring",
                            "children": [
                                {
                                    "id": 1531,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "FirstSymptomSnoring",
                                        "displayTextOptions": [
                                            {
                                                "id": 724,
                                                "displayText": "We will start the diagnostic questioning now."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 611,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Excessive Snoring",
                                        "variable": "Snoring",
                                        "displayTextOptions": [
                                            {
                                                "id": 435,
                                                "displayText": "Does patient have excessive snoring? \nAnswer yes or no."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 628,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 63,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 419,
                                                        "operator": "is",
                                                        "variable": "Snoring",
                                                        "value": "yes"
                                                    }
                                                ],
                                                "next": "490"
                                            },
                                            {
                                                "id": 63.57127860942534,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ],
                                        "next": "520"
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#D3E5F4"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 280,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 667.240651002591,
                            "y": 592.9038668611616
                        },
                        "dragging": false
                    },
                    {
                        "id": "1169",
                        "position": {
                            "x": 1192.6822320088838,
                            "y": 225.1822736165119
                        },
                        "data": {
                            "label": "Obstructive",
                            "children": [
                                {
                                    "id": 490,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Obstructive",
                                        "displayTextOptions": [
                                            {
                                                "id": 15,
                                                "displayText": "Patient is likely having obstructive sleep apnea.\nWill continue along this path."
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 823,
                                    "label": "Question",
                                    "nodeType": "QUESTION",
                                    "stateConfig": {
                                        "name": "Obesity",
                                        "variable": "Obesity",
                                        "displayTextOptions": [
                                            {
                                                "id": 156,
                                                "displayText": "Is patient has obesity (70%)?"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "id": 718,
                                    "label": "Condition",
                                    "nodeType": "CONDITION",
                                    "stateConfig": {
                                        "choices": [
                                            {
                                                "id": 671,
                                                "operator": "OR",
                                                "expressionList": [
                                                    {
                                                        "id": 507,
                                                        "operator": "is",
                                                        "variable": "Obesity",
                                                        "value": "yes"
                                                    }
                                                ],
                                                "next": "968"
                                            },
                                            {
                                                "id": 671.4805228420138,
                                                "operator": "DEFAULT",
                                                "name": "",
                                                "active": true
                                            }
                                        ],
                                        "next": "842"
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 280,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1192.6822320088838,
                            "y": 225.1822736165119
                        },
                        "dragging": false
                    },
                    {
                        "id": "1393",
                        "position": {
                            "x": 1203.6206436567907,
                            "y": 619.8935144368733
                        },
                        "data": {
                            "label": "Central Sleep Apnea",
                            "children": [
                                {
                                    "id": 520,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Need Neurologic examination",
                                        "displayTextOptions": [
                                            {
                                                "id": 699,
                                                "displayText": "The cause of sleep apnea may be neurologic.\nNeed to order Neurologic examination for the patient."
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#E3E0F1"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 1
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1203.6206436567907,
                            "y": 619.8935144368733
                        },
                        "dragging": false
                    },
                    {
                        "id": "1364",
                        "position": {
                            "x": 1680.8457660229492,
                            "y": 109.2394884979642
                        },
                        "data": {
                            "label": "Refer to Specialist",
                            "children": [
                                {
                                    "id": 968,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Specialist referral",
                                        "displayTextOptions": [
                                            {
                                                "id": 910,
                                                "displayText": "The cause of sleep apnea may be due to:\n1. Pickwickian syndrome\n2. Idiopathic obesity\n\nSuggest to see specialist in the above fields."
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": true,
                        "positionAbsolute": {
                            "x": 1680.8457660229492,
                            "y": 109.2394884979642
                        },
                        "dragging": false
                    },
                    {
                        "id": "506",
                        "position": {
                            "x": 1688.0800783087693,
                            "y": 419.5210230040716
                        },
                        "data": {
                            "label": "other treatment",
                            "children": [
                                {
                                    "id": 842,
                                    "label": "Reply Text",
                                    "nodeType": "REPLY_TEXT",
                                    "stateConfig": {
                                        "name": "Physical",
                                        "displayTextOptions": [
                                            {
                                                "id": 611,
                                                "displayText": "Cause of sleep apnea my be due to:\n1. Large tongue\n2. Cleft Palate\n3. Other Nsopharyngeal Abnormalities"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "style": {
                                "backgroundColor": "#FCD4DE"
                            }
                        },
                        "type": "MainWorkflowNode",
                        "dragHandle": ".custom-drag-handle",
                        "style": {
                            "borderRadius": "12px",
                            "width": "300px",
                            "zIndex": 0
                        },
                        "width": 300,
                        "height": 132,
                        "selected": false,
                        "positionAbsolute": {
                            "x": 1688.0800783087693,
                            "y": 419.5210230040716
                        },
                        "dragging": false
                    }
                ],
                "edges": [
                    {
                        "id": "edge-1034-643",
                        "source": "1034",
                        "target": "643",
                        "sourceHandle": "source-1034-878-left",
                        "targetHandle": "top-right",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-643-1169",
                        "source": "643",
                        "target": "1169",
                        "sourceHandle": "source-condition-628-63-right",
                        "targetHandle": "left-top",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-643-1393",
                        "source": "643",
                        "target": "1393",
                        "sourceHandle": "source-else-condition-628-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1169-1364",
                        "source": "1169",
                        "target": "1364",
                        "sourceHandle": "source-condition-718-671-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    },
                    {
                        "id": "edge-1169-506",
                        "source": "1169",
                        "target": "506",
                        "sourceHandle": "source-else-condition-718-right",
                        "targetHandle": "left-bottom",
                        "type": "custom",
                        "markerEnd": {
                            "type": "arrowclosed",
                            "width": 20,
                            "height": 20,
                            "color": "#ACA7FF"
                        },
                        "style": {
                            "strokeWidth": 2,
                            "stroke": "#ACA7FF"
                        }
                    }
                ]
            }
        ],
        totalElements: 3,
    }
}

export const formInfoMessage = {
    "QUESTION": {
        header: "Prompt: ",
        message: "The prompt is the message sent to the user, and the user's response is stored in a slot for further processing.",
    },
    "REPLY_TEXT": {
        header: "Text Message: ",
        message: "The response sent to a user during a conversation, providing information or interaction based on the ongoing dialogue."
    },
    "DEV_WEBHOOK": {
        header: "API Step: ",
        message: "Make custom API calls to retrieve data from external databases or send data to other sources, enabling seamless integration and data exchange with external systems."
    },
    "CONDITION": {
        header: "Conditional State Execution: ",
        message: "Implement varying actions based on \"if, then\" logic. This allows you to set conditions that trigger specific actions only when certain criteria's are met"
    }
}
