import {GRAY_ONE, PRIMARY_COLOR, TXT_PRIMARY_COLOR, WHITE_COLOR} from "../helpers/ChatbotConstant";

export const lightToken = {
    colorPrimary: PRIMARY_COLOR,
    fontFamily: "'Lato', sans-serif",
    customBGC: WHITE_COLOR,
    colorTextBase: TXT_PRIMARY_COLOR,
    fontSize: 14,
}

export const lightComponentsToken = {
    Input: {
        controlHeight: 48,
        activeShadow: "0 0",
        colorBgContainer: GRAY_ONE,
        colorBorder: WHITE_COLOR,
    },
    InputNumber: {
        controlHeight: 48,
        activeShadow: "0 0",
        colorBgContainer: GRAY_ONE,
        colorBorder: WHITE_COLOR,
    },
    Select: {
        optionSelectedBg: PRIMARY_COLOR,
        optionSelectedColor: WHITE_COLOR,
    },
    DatePicker: {
        colorBgContainer: GRAY_ONE,
    },
    Table: {
        colorFillAlter: WHITE_COLOR,
        headerBorderRadius: 0
    },
    Button: {
        borderRadius: "4px",
        fontWeight: 600,
    },
    Menu: {
        itemHeight: 44,
        fontSize: 16,
    },
    Modal: {
        borderRadiusLG: 5,
        titleFontSize: 20
    },
    Form: {
        itemMarginBottom: 14
    },
    Checkbox: {
        borderRadiusSM: 0,
        controlInteractiveSize: 20,
    },
    Dropdown: {
        paddingBlock: 12
    },
    Tag: {
        fontSizeSM: 14,
        lineHeightSM: 2
    }
}