import React, {createContext, useCallback, useState} from 'react';
import {addEdge, MarkerType, useEdgesState, useNodesState} from "reactflow";

export const WorkflowCanvasContext = createContext("WorkflowContext");

const WorkflowCanvasContextProvider = ({children}) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [nodeDrawerVisible, setNodeDrawerVisibility] = useState(false);
    const [nodeAttribute, setNodeAttribute] = useState(null);

    const [edgeLabelModalVisible, setEdgeLabelModalVisible] = useState(false);
    const [selectedEdgeConnection, setSelectedEdgeConnection] = useState(null);
    const [edgeLabel, setEdgeLabel] = useState("");
    const [isConnecting, setIsConnecting] = useState(false);
    const [openConnectionFailedModal, setOpenConnectionFailedModal] = useState({
        open: false,
        positionX: 0,
        positionY: 0,
        nodeId: null,
        handleId: null,
    });

    const [hoveredNodeId, setHoveredNodeId] = useState(null);
    const [startNodeId, setStartNodeId] = useState(null);

    const [successfulConnection, setSuccessfulConnection] = useState(false);

    const [edgeConnectByHoverNode, setEdgeConnectByHoverNode] = useState(false);

    const [metadata, setMetadata] = useState(null);

    const [workflowData, setWorkflowData] = useState(null);
    const [isHolding, setIsHolding] = useState([])

    const onDragStart = (event, nodeData) => {
        event.dataTransfer.setData("application/reactflow", nodeData.nodeType);
        event.dataTransfer.setData('nodeData', JSON.stringify(nodeData));
    };

    const onDragOver = (event) => {
        event.preventDefault(); // Allow dropping by preventing default behavior
    };

    const calculateHandle = (sourcePos, targetPos) => {
        const dx = sourcePos.x - targetPos.x;
        const dy = sourcePos.y - targetPos.y;
        const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
        const normalizedAngle = (angle + 360) % 360;

        if (normalizedAngle >= 337.5 || normalizedAngle < 22.5) {
            return 'right-top';
        } else if (normalizedAngle >= 22.5 && normalizedAngle < 67.5) {
            return 'bottom-right';
        } else if (normalizedAngle >= 67.5 && normalizedAngle < 112.5) {
            return 'bottom-left';
        } else if (normalizedAngle >= 112.5 && normalizedAngle < 157.5) {
            return 'left-top';
        } else if (normalizedAngle >= 157.5 && normalizedAngle < 202.5) {
            return 'left-bottom';
        } else if (normalizedAngle >= 202.5 && normalizedAngle < 247.5) {
            return 'top-left';
        } else if (normalizedAngle >= 247.5 && normalizedAngle < 292.5) {
            return 'top-right';
        } else if (normalizedAngle >= 292.5 && normalizedAngle < 337.5) {
            return 'right-bottom';
        } else {
            return 'top-left'; // Default handle
        }
    };

    const calculateHandleSource = (sourcePos, targetPos) => {
        const dx = sourcePos.x - targetPos.x;
        const dy = sourcePos.y - targetPos.y;
        const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
        const normalizedAngle = (angle + 360) % 360;

        if (normalizedAngle >= 337.5 || normalizedAngle < 22.5) {
            return 'left';
        } else if (normalizedAngle >= 22.5 && normalizedAngle < 67.5) {
            return 'left';
        } else if (normalizedAngle >= 67.5 && normalizedAngle < 112.5) {
            return 'right';
        } else if (normalizedAngle >= 112.5 && normalizedAngle < 157.5) {
            return 'right';
        } else if (normalizedAngle >= 157.5 && normalizedAngle < 202.5) {
            return 'right';
        } else if (normalizedAngle >= 202.5 && normalizedAngle < 247.5) {
            return 'right';
        } else if (normalizedAngle >= 247.5 && normalizedAngle < 292.5) {
            return 'left';
        } else if (normalizedAngle >= 292.5 && normalizedAngle < 337.5) {
            return 'left';
        } else {
            return 'right';// Default handle
        }
    };

    const onConnect = useCallback(
        (connection) => {

            const sourceNode = nodes.find((node) => node.id === connection.source);
            const targetNode = nodes.find((node) => node.id === connection.target);

            console.log('targetNode', connection)
            setSuccessfulConnection(true)

            const closestConnector = calculateHandle(
                sourceNode.positionAbsolute || sourceNode.position,
                targetNode.positionAbsolute || targetNode.position
            );

            setIsConnecting(false)
            console.log("closestConnector", closestConnector)
            setOpenConnectionFailedModal({
                open: false,
                positionX: 0,
                positionY: 0,
                nodeId: null,
                handleId: null,
            })

            const edge = {
                ...connection,
                type: 'custom',
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: '#ACA7FF',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#ACA7FF',
                },
                // targetHandle: closestConnector
            };

            /*
            * Start of maximum one output connection
            * */

            let filteredEdges = edges;

            if (!sourceNode?.data?.children[sourceNode?.data?.children?.length - 1]?.stateConfig?.choices) {
                // if (!sourceNode?.data?.children[sourceNode?.data?.children?.length - 1]?.children) {
                filteredEdges = edges.filter(edg => edg.source !== connection.source);
            }

            /*
            * End of maximum one output connection
            * */


            setEdges(() => addEdge(edge, filteredEdges));

        }, [ nodes, setEdges]
    );

    const onEdgeUpdateForMove = (event, node) => {
        setEdges((eds) => {
            return eds.map((edge) => {
                const sourceNode = nodes.find((n) => n.id === edge.source);
                const targetNode = nodes.find((n) => n.id === edge.target);

                console.log('edge', edge)
                if (sourceNode && targetNode) {
                    const targetHandleId = calculateHandle(
                        sourceNode.positionAbsolute || sourceNode.position,
                        targetNode.positionAbsolute || targetNode.position
                    );

                    const sourceHandleClass = calculateHandleSource(
                        sourceNode.positionAbsolute || sourceNode.position,
                        targetNode.positionAbsolute || targetNode.position
                    );
                    let sourceHandleId = edge.sourceHandle.replaceAll('left', '')
                    sourceHandleId = sourceHandleId.replaceAll('right', '')
                    sourceHandleId = sourceHandleId + sourceHandleClass
                    console.log('!!!! sourceHandleId', sourceHandleId)
                    return {
                        ...edge,
                        targetHandle: targetHandleId,
                        sourceHandle: sourceHandleId
                    };
                }
                return edge;
            });
        });
    }

    const openNodeDrawer = (item, parentId) => {

        setNodeAttribute({...item, parentId});
        setNodeDrawerVisibility(true);

    };

    const closeNodeDrawer = () => {

        setNodeDrawerVisibility(false);

    }

    const handleNodeConfigChange = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                [name]: value,
            }
        }

        setNodeAttribute(_nodeAttribute);
    }

    const addNodeAttributeTextVariants = () => {

        let _nodeAttributeTextVariants = [];

        if (nodeAttribute?.stateConfig?.displayTextOptions?.length > 0) {
            _nodeAttributeTextVariants = nodeAttribute.stateConfig.displayTextOptions;
        }

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: [
                    ..._nodeAttributeTextVariants,
                    {id: new Date().getMilliseconds()}
                ]
            },
        }

        setNodeAttribute(_nodeAttribute);

    }

    const deleteNodeAttributeTextVariants = id => {

        const _nodeAttributeTextVariants = nodeAttribute?.stateConfig?.displayTextOptions?.filter(nodeAttributeTextVariant => nodeAttributeTextVariant.id !== id);

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: _nodeAttributeTextVariants
            }
        }

        setNodeAttribute(_nodeAttribute);

    }

    const nodeAttributeTextVariantOnchange = (e, id) => {

        const {name, value} = e.target;

        const _nodeAttributeTextVariants = nodeAttribute?.stateConfig?.displayTextOptions?.map(nodeAttributeTextVariant => {
            if (nodeAttributeTextVariant.id === id) {
                return {
                    ...nodeAttributeTextVariant,
                    [name]: value
                }
            }
            return nodeAttributeTextVariant;
        });

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: _nodeAttributeTextVariants,
            }
        }

        setNodeAttribute(_nodeAttribute)

    }

    const handleDevWebhookConfig = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                webhookConfig: {
                    ...nodeAttribute.stateConfig.webhookConfig,
                    [name]: value,
                }
            }
        }

        setNodeAttribute(_nodeAttribute);
    }

    const closeEdgeLabelModal = () => {
        setEdgeLabelModalVisible(false);
    }

    const handleEdgeLabelAdd = () => {
        if (selectedEdgeConnection) {
            // When the user clicks 'OK' in the modal, add the edge with the provided label
            const edge = {
                ...selectedEdgeConnection,
                type: 'custom',
                label: edgeLabel, // Use the input value as the label
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 20,
                    height: 20,
                    color: '#00A89E',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#00A89E',
                },
            };

            setEdges((eds) => addEdge(edge, eds));
            setEdgeLabel('');
            setSelectedEdgeConnection(null);

            closeEdgeLabelModal();

        }
    }

    const handleEdgeLabelChange = value => {
        setEdgeLabel(value);
    }

    return (
        <WorkflowCanvasContext.Provider
            value={{
                nodes,
                setNodes,
                edges,
                setEdges,
                onNodesChange,
                onEdgesChange,
                nodeDrawerVisible,
                edgeLabelModalVisible,
                onConnect,
                nodeAttribute,
                setNodeAttribute,
                setMetadata,
                metadata,
                workflowData,
                setWorkflowData,
                setIsConnecting,
                isConnecting,
                openConnectionFailedModal,
                setOpenConnectionFailedModal,
                handleDevWebhookConfig,
                openNodeDrawer,
                onDragStart,
                onDragOver,
                closeNodeDrawer,
                handleNodeConfigChange,
                handleEdgeLabelAdd,
                handleEdgeLabelChange,
                closeEdgeLabelModal,
                addNodeAttributeTextVariants,
                deleteNodeAttributeTextVariants,
                nodeAttributeTextVariantOnchange,
                onEdgeUpdateForMove,
                hoveredNodeId,
                setHoveredNodeId,
                setSuccessfulConnection,
                successfulConnection,
                setEdgeConnectByHoverNode,
                edgeConnectByHoverNode,
                setStartNodeId,
                startNodeId,
                setIsHolding,
                isHolding
            }}
        >
            {children}
        </WorkflowCanvasContext.Provider>
    );
}

export default WorkflowCanvasContextProvider;
