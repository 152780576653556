import React, {createContext, useState} from 'react';
import SettingService from "../services/SettingService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const SettingContext = createContext("SettingContext");

const SettingContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [setting, setSetting] = useState(null);

    const getSetting = async () => {
        try {

            setLoading(true);

            const res = await SettingService.getSetting();
            setSetting(res.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <SettingContext.Provider
            value={{
                loading,
                setting,
                setSetting,
                getSetting,
            }}
        >
            {children}
        </SettingContext.Provider>
    );
}

export default SettingContextProvider;
