import * as PATH from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class UserService {

    static createUser = data => privateAPI.post(PATH.CREATE_USER_URL, data)

    static getUserList = params => privateAPI.get(PATH.GET_ALL_USER_URL, {params});

    static updateUser = (id, data) => privateAPI.put(`${PATH.UPDATE_USER_URL}/${id}`, data)

    static updateUserProfile = data => privateAPI.put(PATH.UPDATE_USER_PROFILE_URL, data)

    static getProfile = () => privateAPI.get(PATH.GET_USER_PROFILE_URL);

    static changePassword = data => privateAPI.put(PATH.CHANGE_USER_PASSWORD, data);

}
