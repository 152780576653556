import React, {createContext, useState} from 'react';
import UserActivityLogService from "../services/UserActivityLogService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const UserActivityLogContext = createContext("UserActivityLogContext");

const UserActivityLogContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [userActivityLogList, setUserActivityLogList] = useState([]);

    const getUserActivityLogList = async (id, params) => {
        try {

            setLoading(true);

            const res = await UserActivityLogService.getUserActivityLogList(id, params);
            setUserActivityLogList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <UserActivityLogContext.Provider
            value={{
                loading,
                totalElements,
                userActivityLogList,
                getUserActivityLogList,
            }}
        >
            {children}
        </UserActivityLogContext.Provider>
    );
}

export default UserActivityLogContextProvider;
