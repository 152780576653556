import {notification} from "antd";

export const Toast = (type, title, desc) => {

    if (typeof title !== 'string') {
        desc = "error";
    }

    if (typeof desc !== 'string') {
        desc = "Error description not found";
    }

    notification[type]({
        message: title,
        description: desc,
    });
};
