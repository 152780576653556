import React, {createContext, useState} from 'react';
import ProductionFileService from "../services/ProductionFileService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const ProductionFileContext = createContext("ProductionFileContext");

const ProductionFileContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [productionFileList, setProductionFileList] = useState([]);

    const getProductionFileList = async params => {
        try {

            setLoading(true);

            const res = await ProductionFileService.getProductionFileList(params);
            setProductionFileList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <ProductionFileContext.Provider
            value={{
                loading,
                totalElements,
                productionFileList,
                getProductionFileList,
            }}
        >
            {children}
        </ProductionFileContext.Provider>
    );
}

export default ProductionFileContextProvider;
