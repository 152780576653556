import * as PATH from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class ChatbotService {

    static createChatbot = data =>
        privateAPI.post(PATH.CREATE_CHATBOT_URL, data);

    static getChatbotList = params =>
        privateAPI.get(PATH.GET_ALL_CHATBOT_URL, {params});

    static getChatbotListForProd = params =>
        privateAPI.get(PATH.GET_ALL_CHATBOT_FOR_PROD_URL, {params});

    static getChatbotById = id =>
        privateAPI.get(`${PATH.GET_CHATBOT_BY_ID_URL}/${id}`);

    static getLanguagesByChatbot = id =>
        privateAPI.get(`${PATH.GET_LANGUAGES_BY_CHATBOT_ID_URL}/${id}`)

    static updateChatbot = (data, id) =>
        privateAPI.put(`${PATH.UPDATE_CHATBOT_URL}/${id}`, data)

}
