import {lazy, Suspense, useContext} from "react";
import LoadingSuspense from "./components/common/LoadingSuspense";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {
    CREATE_NEW_PASSWORD_PATH,
    FORGOT_PASSWORD_OTP_PATH,
    FORGOT_PASSWORD_PATH,
    LOGIN_PATH,
    PAGE_403_PATH,
    PAGE_404_PATH,
    PAGE_500_PATH,
    PASSWORD_CREATE_SUCCESS_PATH,
    TWO_FACTOR_VERIFICATION_PATH,
    WORKFLOW_CHATBOT_PATH
} from "./routes/Slug";
import DefaultLayout from "./components/layout/DefaultLayout";
import {ConfigProvider} from "antd";
import {lightComponentsToken, lightToken} from "./them_token/light";
import PrivateRoute from "./components/common/PrivateRoute";
import {AuthContext} from "./contexts/AuthContextProvider";
import Interceptors from "./rest_handlers/Interceptors";

const Page403 = lazy(() => import("./pages/error_pages/Page403"));
const Page404 = lazy(() => import("./pages/error_pages/Page404"));
const Page500 = lazy(() => import("./pages/error_pages/Page500"));

const Login = lazy(() => import("./pages/auth/Login"));
const TwoFactor = lazy(() => import("./pages/auth/TwoFactor"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ForgotPasswordOtp = lazy(() => import("./pages/auth/ForgotPasswordOtp"));
const CreateNewPassword = lazy(() => import("./pages/auth/SetFirstPassword"));
const CreateNewPasswordSuccess = lazy(() => import("./pages/auth/PasswordCreateSuccess"));
const AiChatbot = lazy(() => import("./pages/workflow/AiChatbot"));

const App = () => {

    const {isLogin} = useContext(AuthContext);


    // console.log("DEvice detect", deviceDetect(window.navigator.userAgent));

    return (
        <ConfigProvider
            theme={{
                token: {...lightToken},
                components: lightComponentsToken
            }}
        >
            <div className="app-wrapper">
                <Suspense fallback={<LoadingSuspense/>}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={LOGIN_PATH} element={<Login/>}/>
                            <Route path={TWO_FACTOR_VERIFICATION_PATH} element={<TwoFactor/>}/>
                            <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword/>}/>
                            <Route path={FORGOT_PASSWORD_OTP_PATH} element={<ForgotPasswordOtp/>}/>
                            <Route path={CREATE_NEW_PASSWORD_PATH} element={<CreateNewPassword/>}/>
                            <Route path={PASSWORD_CREATE_SUCCESS_PATH} element={<CreateNewPasswordSuccess/>}/>
                            <Route element={<PrivateRoute isLogin={isLogin}/>}>
                                <Route path={PAGE_404_PATH} element={<Page404/>}/>
                                <Route path={PAGE_403_PATH} element={<Page403/>}/>
                                <Route path={PAGE_500_PATH} element={<Page500/>}/>
                                <Route path="*" element={<DefaultLayout/>}/>
                            </Route>
                            <Route path={WORKFLOW_CHATBOT_PATH} element={<AiChatbot/>}/>
                        </Routes>
                        <Interceptors/>
                    </BrowserRouter>
                </Suspense>
            </div>
        </ConfigProvider>
    );
}

export default App;
