import React, {createContext, useState} from 'react';
import TelegramService from "../../services/third_party/TelegramService";
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";

export const TelegramContext = createContext("TelegramContext");

const TelegramContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [telegramChatbotList, setTelegramChatbotList] = useState([]);

    const getTelegramChatbotList = async params => {
        try {

            setLoading(true);

            const res = await TelegramService.getTelegramUserList(params);
            setTelegramChatbotList(res.data.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <TelegramContext.Provider
            value={{
                loading,
                telegramChatbotList,
                getTelegramChatbotList,
            }}
        >
            {children}
        </TelegramContext.Provider>
    );
}

export default TelegramContextProvider;
