import publicAPI from "../rest_handlers/publicAPI";
import * as PATH from "../configs/APIUrl";

export default class AuthService {

    static login = data =>
        publicAPI.post(PATH.USER_SIGN_IN_URL, data);

    static loginRequestOtpVerify = data =>
        publicAPI.post(PATH.OTP_VERIFY_URL, data);

    static forgotPassword = params => {
        return publicAPI.post(PATH.FORGOT_PASSWORD_URL, null, {params})
    }

    static resendOtp = params =>
        publicAPI.post(PATH.RESEND_OTP_URL, null, {params});

}
