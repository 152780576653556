import {useLocation} from "react-router-dom";
import {ACCESS_TOKEN, CONDITION_NODE_TYPE, DATE_FORMAT, TIME_FORMAT} from "./Constant";
import dayjs from "dayjs";
import Roles from "./Roles";
import {RETRIEVE_IMAGE_URL} from "../configs/APIUrl";
import utc from 'dayjs/plugin/utc';

export const authorizationHeader = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return {"Authorization": `Bearer ${accessToken}`};
}

export const getErrorMessage = (error) => {

    if (error.response) {

        if (error.response.data) {
            return error.response.data.message || error.response.data.error || error.response.data.detail;
        }

        return error.response.data;
    } else {
        return error.message;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {

        let param = query.get(key);

        if (Array.isArray(data[key])) {

            if (param) {
                param = param.split(",");
            } else {
                param = [];
            }
        }

        data = {...data, [key]: param || ""}
    }
    return data;
}

export const dateTimeFormat = date => {
    if (date) {
        return dayjs(date).format("YYYY-MM-DD, h:mm A");
    } else {
        return "N/A"
    }
}

export const fileDownloadDateFormat = date => {
    if (date) {
        return dayjs(date).format("YYYY-MM-DD_h:mm_A");
    } else {
        return "N/A"
    }
}

export const enumFormatter = value => {

    if (!value) return "";

    return value.replaceAll("_", " ");
}

export const hasRole = (loggedInUserRoles, roles) => {

    if (!roles) {
        return false;
    }

    if (roles.includes(Roles.ALL)) {
        return true;
    }

    if (!loggedInUserRoles) {
        return false;
    }

    for (const permission of roles) {

        if (loggedInUserRoles.includes(permission)) {
            return true;
        }

    }

    return false;
}

export const longToDate = date => {
    if (!date) return;
    return new Date(date);
}

export const longToDateTimeFormatter = date => {

    if (!date) return;

    dayjs.extend(utc);

    return dayjs.utc(longToDate(date)).local().format(`${DATE_FORMAT} ${TIME_FORMAT}`);

}

export const longToDateFormatter = date => {

    if (!date) return;

    dayjs.extend(utc);

    return dayjs.utc(longToDate(date)).local().format(`${DATE_FORMAT}`);

}

export const longToTimeFormatter = date => {

    if (!date) return;

    dayjs.extend(utc);

    return dayjs.utc(longToDate(date)).local().format(`${TIME_FORMAT}`);

}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length < 1) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        let param = params[key];

        if (Array.isArray(params[key])) {
            param = param.toString();
        }

        if (index === 0) {
            result += `?${key}=${param}`;
        } else {
            result += `&${key}=${param}`;
        }

    });

    return result;
}

export const getNameFromPath = (path) => {

    if (path) {
        const split = path.split("/");
        return split[split.length - 1];
    }

}

export const retrieveImageUrl = (path) => {

    if (!path) return null;

    return `${RETRIEVE_IMAGE_URL}?key=${path}`;
};

// swap positions of array object
export const swapPositions = (array, id1, id2) => {
    // Find the index of the first and second IDs
    const index1 = array.findIndex(item => item.id === id1);
    const index2 = array.findIndex(item => item.id === id2);

    // If both IDs are found, swap their positions
    if (index1 !== -1 && index2 !== -1) {
        [array[index1], array[index2]] = [array[index2], array[index1]];
    } else {
        console.log('One or both IDs not found');
    }
    return array;
};

// pleased condition type at the last index
export function moveConditionToLast(arr) {
    const index = arr.findIndex(item => item.nodeType === CONDITION_NODE_TYPE);

    if (index !== -1) {
        const [item] = arr.splice(index, 1); // Remove the item with id 2
        arr.push(item); // Add it to the end
    }

    return arr;
}

export const enumToCapitalize = value => {
    if (!value) return "";
    const splitWord = value.split("_");
    return splitWord.map(word => word[0].toUpperCase() + word.substring(1).toLowerCase()).join(" ");
}

export const moveDefaultOperatorToEnd = (arr) => {
    // Separate "Default" operator objects from others
    const nonDefaultOperators = arr.filter(item => item.operator !== "DEFAULT");
    const defaultOperators = arr.filter(item => item.operator === "DEFAULT");

    // Concatenate non-default operators first, followed by default operators
    return [...nonDefaultOperators, ...defaultOperators];
}

export const transformSchema = initialSchema => {

    const targetSchema = {
        startAt: null,
        states: {}
    };

    // Helper function to process children nodes recursively
    function processNode(node, nextNodeId = null) {
        const {id, nodeType, stateConfig} = node;

        if (!stateConfig) {
            return
        }

        if (stateConfig && nodeType === 'CONDITION' && stateConfig.choices.length === 0) {
            return
        }

        // Assign stateConfig and next (next only if it exists)
        targetSchema.states[id] = {
            type: nodeType,
            stateConfig,
            ...(nextNodeId ? {next: nextNodeId} : {})
        };

        if (!targetSchema.startAt) {
            targetSchema.startAt = String(id);
        }
    }

    // Traverse the nodes and build the target schema
    initialSchema.nodes.forEach(mainNode => {
        const children = mainNode.data.children;

        children.forEach((childNode, index) => {
            const nextChild = children[index + 1] ? String(children[index + 1].id) : null;
            processNode(childNode, nextChild);
        });
    });

    // Handle edges to connect different blocks
    initialSchema.edges.forEach(edge => {
        const { source, target } = edge;
        let { sourceHandle } = edge;

        const firstTargetChild = initialSchema.nodes
            .find(node => node.id === target)
            .data.children[0];

        sourceHandle = sourceHandle.replaceAll('-right', '').replaceAll('-left', '')
        if (sourceHandle.startsWith('source-condition')) {
            const splits = sourceHandle.replaceAll('source-condition-', '').split('-')
            const stepId = +splits[0]
            const choicePathId = +splits[1]

            const choice = targetSchema.states[stepId].stateConfig.choices
                .find(choice => choice.id === choicePathId)
            choice.next = String(firstTargetChild.id);
        } else if (sourceHandle.startsWith('source-else-condition')) {
            const stepId = sourceHandle.replaceAll('source-else-condition-', '')
            targetSchema.states[stepId].stateConfig.next = String(firstTargetChild.id);
        } else {
            const node = initialSchema.nodes
                .find(node => node.id === source)
            if (node) {
                const lastSourceChild = node.data.children.slice(-1)[0];
                targetSchema.states[lastSourceChild.id].next = String(firstTargetChild.id);
            }

        }
    });

    console.log("Target Schema", targetSchema)

    return targetSchema;
}
