import privateAPI from "../rest_handlers/privateAPI";
import *  as PATH from "../configs/APIUrl";

export default class ProductionFileService {

    static createProductionFile = data =>
        privateAPI.post(PATH.CREATE_PRODUCTION_FILE_URL, data);

    static getProductionFileList = params =>
        privateAPI.get(PATH.GET_ALL_PRODUCTION_FILE_URL, {params});

    static getProductionHistoryList = id =>
        privateAPI.get(`${PATH.GET_ALL_PRODUCTION_HISTORY_URL}/${id}`);

    static updateProductionFile = (data, id) =>
        privateAPI.put(`${PATH.UPDATE_PRODUCTION_FILE_URL}/${id}`, data);

    static disableProductionFile = (params, id) =>
        privateAPI.put(`${PATH.DISABLE_PRODUCTION_FILE_URL}/${id}`, null, {params});

}
